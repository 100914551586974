<template>
  <div class="box" pd="9xl-y" halign="center">
    <NuxtLink to="/" class="box" :title="$t('go-home')">
      <Image
        :image="globals.getImage(`/base/logo-symbol-primary.webp`)"
        :width="`3rem`"
        :height="`3rem`"
      />
    </NuxtLink>
  </div>
</template>